import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './FlexibleColumnPanel.scss';

export const FlexibleColumnPanel = ( props ) => {
  const data = props.data.body[ props.index ];
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "flexible-column-panel", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="wrapper-items">
        {!data?.primary?.no_text_panel && (
          <div className="item-panel only-text">
            <h2>{ data?.primary?.main_subtitle && ( <span>{ data?.primary.main_subtitle }</span> ) }{ data?.primary.main_title }</h2>
          </div>
        )}
        {
          data?.items.map( ( item, index ) => {
            return (
              <div className="item-panel" key={ index }>
                <img alt={ item.imagealt || item.title } src={ item.image.url } className={classnames( item.image_size ? 'small' : '' )} />
                <h3>{ item.title }</h3>
                <p>{ item.description }</p>
              </div>
            );
          } )
        }
      </div>
    </div>
  );
};