import React, { useState, useEffect, useRef } from "react";
import { CustomLink } from "../utils/CustomLink";
import classnames from "classnames";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import "./HomeHero.scss";
import AudioActive from "../images/audio-active.png";
import AudioMuted from "../images/audio-muted.png";

export const HomeHero = (props) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const [ windowWidth, setWindowWidth ] = useState(0);
  const [ mobile, setMobile ] = useState(false);
  const [ muted, setMuted ] = useState(true);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateSize);
    window.addEventListener("orientationchange", updateSize);
    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
      window.removeEventListener("orientationchange", updateSize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth != 0) {
      if (windowWidth <= 992) {
        setMobile(true);
        setMuted(true);
      } else {
        setMobile(false);
        setMuted(true);
      }
    }
  }, [ windowWidth ]);

  const handleMute = () => {
    setMuted(!muted);
    let videoEl = document.getElementById("hero-vid");
    videoEl.muted ? (videoEl.muted = false) : (videoEl.muted = true);
  };

  return (
    <div
      className={classnames("home-hero-component",
        onScreen?.isIntersecting ? "visible" : "")}
      ref={wrapperRef}
    >
      <div className="hero-copy">
        {data?.buttoncta && data?.buttontext && (
          <CustomLink
            element={data?.buttoncta}
            content={data?.buttontext}
          ></CustomLink>
        )}
      </div>
      <div className="mute-container" onClick={handleMute}>
        <img src={muted ? AudioMuted : AudioActive} />
      </div>
      {mobile &&
        data?.videomobile &&
        data?.videomobile?.kind === "document" &&
        data?.videomobile?.url && (
        <div
          className="background"
          dangerouslySetInnerHTML={{
            __html: `
              <video
                id="hero-vid"
                loop
                muted
                autoplay
                playsinline
                src="${data?.videomobile?.url}"
              />
            `,
          }}
        ></div>
      )}
      {!mobile &&
        data?.videodesktop &&
        data?.videodesktop?.kind === "document" &&
        data?.videodesktop?.url && (
        <div
          className="background"
          dangerouslySetInnerHTML={{
            __html: `
              <video
                id="hero-vid"
                loop
                muted
                autoplay
                playsinline
                src="${data?.videodesktop?.url}"
              />
            `,
          }}
        ></div>
      )}
      {!data?.videodesktop && data?.image.url && (
        <img alt={data.imagealt || "home hero image"} src={data.image.url} />
      )}
    </div>
  );
};
