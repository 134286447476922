import React, { useRef, useEffect, useState } from 'react';
import { CustomLink } from '../utils/CustomLink';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './InternalHeroBanner.scss';

export const InternalHeroBanner = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );
  const [ windowWidth, setWindowWidth ] = useState( 0 );
  const [ mobile, setMobile ] = useState( false );

  // deafult to desktop if not mobile asset present
  const mobileVideoFallback = data?.videomobile?.kind !== "document" ? data.videodesktop : null;  

  useEffect( () => {
    setWindowWidth( window.innerWidth );
  }, [] );

  useEffect( () => {
    const updateSize = () => {
      setWindowWidth( window.innerWidth );
    };
    window.addEventListener( "resize", updateSize );
    window.addEventListener( "orientationchange", updateSize );
    updateSize();
    return () => {
      window.removeEventListener( "resize", updateSize );
      window.removeEventListener( "orientationchange", updateSize );
    };
  }, [] );

  useEffect( () => {
    if ( windowWidth != 0 ) {
      if ( windowWidth <= 992 ) 
        setMobile( true );
      else 
        setMobile( false );
    }
  }, [ windowWidth ] );

  return (
    <div className={ classnames( "internal-hero-banner-component", onScreen?.isIntersecting ? 'visible' : '', data?.alternate_layout ? "alt" : "", data?.hide_image_on_mobile ? "no-mobile-img" : "" ) } ref={ wrapperRef }>
      <div className={classnames( "ih-copy", data?.alternate_layout ? "alt" : "", data?.hide_image_on_mobile ? "no-mobile-img" : "" )}>
        { data?.subtitle && !data?.alternate_layout && (
          <p className="ih-subtitle">{ data?.subtitle }</p>
        ) }
        <h1 className={ data?.alternate_layout ? "alt" : ""}>{ data?.title }</h1>
        { data?.buttoncta && data?.buttontext && (
          <CustomLink element={ data?.buttoncta } content={ data?.buttontext }></CustomLink>
        ) }
        { data?.subtitle && data?.alternate_layout && (
          <p className={classnames( "ih-subtitle", data?.alternate_layout ? "alt" : "" )}>{ data?.subtitle }</p>
        ) }
      </div>
      {mobile &&
        ( ( data?.videomobile &&
        data?.videomobile?.kind === "document" &&
        data?.videomobile?.url ) ||
        ( mobileVideoFallback &&
          mobileVideoFallback?.kind === "document" &&
          mobileVideoFallback?.url ) ) && (
        <div
          className="background"
          dangerouslySetInnerHTML={{
            __html: `
              <video
                class="${mobileVideoFallback ? 'no-mobile' : ''}"
                loop
                muted
                autoplay
                playsinline
                src="${data?.videomobile?.url || mobileVideoFallback?.url}"
              />
            `,
          }}
        ></div>
      )}
      {!mobile &&
        data?.videodesktop &&
        data?.videodesktop?.kind === "document" &&
        data?.videodesktop?.url && (
        <div
          className="background"
          dangerouslySetInnerHTML={{
            __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                src="${data?.videodesktop?.url}"
              />
            `,
          }}
        ></div>
      )}
      { ( !data?.videodesktop || typeof ( data?.videoupload ) === 'object' ) && data?.image.url && (
        <img className={classnames( data?.alternate_layout ? "alt" : "", data?.hide_image_on_mobile ? "no-mobile-img" : "" )} alt={ data.imagealt || "internal hero banner image" } src={ data.image.url } />
      ) }
    </div>
  );
};