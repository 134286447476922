import React, { useRef } from 'react';
import classnames from 'classnames';
// import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './FullWidthMedia.scss';
import { Media } from './Media';

export const FullWidthMedia = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  // TODO: Fix intersection observer 
  // const onScreen = useIntersectionObserver( wrapperRef );

  if (!data) return;
  const media = data.medialink && {
    ...data.medialink,
    alt: data.mediaalt || "full width media" ,
    classnames: "media-full" ,
    posterurl: data.poster?.url
  } || {};
  return (
    <div className={ classnames( "full-width-media-component visible") } ref={ wrapperRef }>
      <div className="media-wrapper">
        <Media {...media}/>
      </div>
    </div>
  );
};