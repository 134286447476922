import React, { useRef } from 'react';
import { CustomLink } from '../utils/CustomLink';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './Pillars.scss';

export const Pillars = ( props ) => {
  const data = props.data.body[ props.index ];
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "pillars-component", onScreen?.isIntersecting ? 'visible' : '', data?.primary?.layout ? 'large-layout' : '' ) } ref={ wrapperRef }>
      <div className="pillars-copy">
        { !data?.primary?.layout && (
          <>
            <h2 className="pillar-title">{ data?.primary?.title }</h2>
            <p className="pillar-description">{ data?.primary?.description }</p>
            { data?.primary?.buttoncta && data?.primary?.buttontext && (
              <CustomLink element={ data?.primary?.buttoncta } content={ data?.primary?.buttontext }></CustomLink>
            ) }
          </>
        ) }
        { data?.primary.layout && (
          <>
            <h2 className="pillar-title large-layout">{ data?.primary?.title }</h2>
            <p className="pillar-description large-layout">{ data?.primary?.description }</p>
          </>
        ) }

      </div>
      <div className="pillars-details">
        {
          data?.items.map( ( item, index ) => {
            return (
              <div className="pillars-item" key={ index }>
                <p className="item-title">{ item.title }</p>
                <p className="item-description">{ item.description }</p>
              </div>
            );
          } )
        }
      </div>
    </div>
  );
};