import React, { useState, useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './ExpandableClientLogos.scss';

export const ExpandableClientLogos = ( props ) => {
  const data = props.data.body[ props.index ];
  const [ condition, setCondition ] = useState( 0 );
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  const calculatePosition = ( current, active, length, value ) => {
    let left, top, order;
    if ( current === active )
      order = length;
    else {
      order = active - current;
      if ( order < 0 ) order = order + length;
    }

    left = -1 * ( length - order ) * value + value;
    top = ( length - order - 1 ) * value;
    return { left, top, order };
  };

  return (
    < div className={ classnames( "expandable-client-logos-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef } >
      <div className="logos-wrapper">
        <div className="left-wrapper">
          <div className='mobile-wrapper'>
            <img alt={ data?.items[ 0 ]?.imagemobilealt || 'logo image mobile' } src={data?.items[ 0 ]?.imagemobile?.url} className='mobile-img' />
          </div>
          {
            data?.items.map( ( item, index ) => {
              return (
                <div className={ `wrapper-image ${ condition === index ? "active" : "" }` } key={ index } data-index={ index }  >
                  <img alt={ item.imagedesktopalt || 'logo image desktop' } src={ item.image.url } className='desktop-img' style={ { "--left": calculatePosition( index, condition, data.items.length, 25 ).left + 'px', "--top": calculatePosition( index, condition, data.items.length, 25 ).top + 'px', "--order": calculatePosition( index, condition, data.items.length, 25 ).order } } />
                </div>
              );
            } )
          }
        </div>
        <div className="right-wrapper">
          {
            data?.items.map( ( item, index ) => {
              return (
                <div className={ classnames( `wrapper-text ${ condition === index ? "active" : "" }`, item.multiline_text ? "multi" : "" ) } key={ index }>
                  <div className='wrapper-left' onClick={ () => setCondition( index ) }>
                    <span>{ item.number }</span>
                  </div>
                  <div className="wrapper-right">
                    <h3 onClick={ () => setCondition( index ) }>
                      { item.main_title }
                    </h3>
                    { item?.description && (
                      <div className="description">{ RichText.render( item?.description ) }</div>
                    ) }
                  </div>
                </div>
              );
            } )
          }
        </div>
      </div>
    </div >
  );
};