import React, { useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
// import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './MediaTextGrid.scss';
import { Media } from './Media';

export const MediaTextGrid = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  // TODO: Fix IntersectionObserver ref undefined.
  // const onScreen = useIntersectionObserver( wrapperRef );

  if( !data ) return;
  const primaryMedia = data.primary_media && {
    ...data.primary_media,
    alt: data.primarymediaalt || "primary media",
    posterurl: data.primary_poster?.url
  } || {};

  const secondaryMedia = data.secondary_media && {
    ...data.secondary_media,
    alt: data.secondarymediaalt || "secondary media",
    posterurl: data.secondary_poster?.url
  } || {};

  return (
    <div className={ classnames( "media-text-grid-component visible") } ref={ wrapperRef }>
      <div className="wrapper-items">
        <div className="left-item">
          <Media {...primaryMedia}/>
        </div>
        <div className="center-item">
          { data?.primary_title && data?.primary_description && (
            <>
              { RichText.render( data?.primary_title ) }
              { RichText.render( data?.primary_description ) }
            </>
          ) }
        </div>
        <div className="right-item">
          <Media {...secondaryMedia}/>
          { data?.secondary_title && data?.secondary_description && (
            <>
              { RichText.render( data?.secondary_title ) }
              { RichText.render( data?.secondary_description ) }
            </>
          ) }
        </div>
      </div>
    </div>
  );
};