import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { ContactCards } from '../components/ContactCards';
import { StaticTwoColumn } from '../components/StaticTwoColumn';
import { ExpandableClientLogos } from '../components/ExpandableClientLogos';
import { CaseStudy } from '../components/CaseStudy';
import { RichTextComponent } from '../components/RichText';
import { Quotes } from '../components/Quotes';
import { HomeHero } from '../components/HomeHero';
import { HighlightTextBackground } from '../components/HighlightTextBackground';
import { HighlightTextTwoColumns } from '../components/HighlightTextTwoColumns';
import { Logos } from '../components/Logos';
import { Cards } from '../components/Cards';
import { Pillars } from '../components/Pillars';
import { InternalHeroBanner } from '../components/InternalHeroBanner';
import { TwoColumnsContent } from '../components/TwoColumnsContent';
import { FullWidthImage } from '../components/FullWidthImage';
import { CreativeProcess } from '../components/CreativeProcess';
import { TextInColumns } from '../components/TextInColumns';
import { GridImages } from '../components/GridImages';
import { ImageTextGrid } from '../components/ImageTextGrid';
import { FlexibleColumnPanel } from '../components/FlexibleColumnPanel';
import { CaseStudyTwoColumns } from '../components/CaseStudyTwoColumns';
import { CaseStudyImageColumns } from '../components/CaseStudyImageColumns';
import { LegalParagraph } from '../components/LegalParagraph';
import { GridMedia } from '../components/GridMedia';
import { CaseStudyMediaColumns } from '../components/CaseStudyMediaColumns';
import { MediaTextGrid } from '../components/MediaTextGrid';
import { FullWidthMedia } from '../components/FullWidthMedia';
import { CaseStudyTwoColumnsMedia } from '../components/CaseStudyTwoColumnsMedia';
import { CaseStudyVideoColumns } from '../components/CaseStudyVideoColumns';
import { CaseStudyTextVideo } from '../components/CaseStudyTextVideo';
import { ContactForm } from '../components/ContactForm';
import { NewsletterForm } from '../components/NewsletterForm';


const RenderComponent = ( { data } ) => {
  const renderSwitch = ( type, data, index ) => {
    switch ( type ) {
    case 'statictwocolumn':
      return <StaticTwoColumn key={ type + '-' + index } data={ data } index={ index } />;
    case 'expandableclientlogos':
      return <ExpandableClientLogos key={ type + '-' + index } data={ data } index={ index } />;
    case 'casestudy':
      return <CaseStudy key={ type + '-' + index } data={ data } index={ index } />;
    case 'richtext':
      return <RichTextComponent key={ type + '-' + index } data={ data } index={ index } />;
    case 'quotes':
      return <Quotes key={ type + '-' + index } data={ data } index={ index } />;
    case 'homehero':
      return <HomeHero key={ type + '-' + index } data={ data } index={ index } />;
    case 'highlight_text_background':
      return <HighlightTextBackground key={ type + '-' + index } data={ data } index={ index } />;
    case 'highlight_text_two_columns':
      return <HighlightTextTwoColumns key={ type + '-' + index } data={ data } index={ index } />;
    case 'logos':
      return <Logos key={ type + '-' + index } data={ data } index={ index } />;
    case 'cards':
      return <Cards key={ type + '-' + index } data={ data } index={ index } />;
    case 'pillars':
      return <Pillars key={ type + '-' + index } data={ data } index={ index } />;
    case 'internalherobanner':
      return <InternalHeroBanner key={ type + '-' + index } data={ data } index={ index } />;
    case 'twocolumnscontent':
      return <TwoColumnsContent key={ type + '-' + index } data={ data } index={ index } />;
    case 'fullwidthimage':
      return <FullWidthImage key={ type + '-' + index } data={ data } index={ index } />;
    case 'fullwidthmedia':
      return <FullWidthMedia key={ type + '-' + index } data={ data } index={ index } />;
    case 'creativeprocess':
      return <CreativeProcess key={ type + '-' + index } data={ data } index={ index } />;
    case 'textincolumns':
      return <TextInColumns key={ type + '-' + index } data={ data } index={ index } />;
    case 'gridimages':
      return <GridImages key={ type + '-' + index } data={ data } index={ index } />;
    case 'gridmedia':
      return <GridMedia key={ type + '-' + index } data={ data } index={ index } />;
    case 'imagetextgrid':
      return <ImageTextGrid key={ type + '-' + index } data={ data } index={ index } />;
    case 'mediatextgrid':
      return <MediaTextGrid key={ type + '-' + index } data={ data } index={ index } />;
    case 'flexiblecolumnpanel':
      return <FlexibleColumnPanel key={ type + '-' + index } data={ data } index={ index } />;
    case 'contactcards':
      return <ContactCards key={ type + '-' + index } data={ data } index={ index } />;
    case 'case_study_two_columns':
      return <CaseStudyTwoColumns key={ type + '-' + index } data={ data } index={ index } />;
    case 'case_study_two_columns_media':
      return <CaseStudyTwoColumnsMedia key={ type + '-' + index } data={ data } index={ index } />;
    case 'case_study_image_columns':
      return <CaseStudyImageColumns key={ type + '-' + index } data={ data } index={ index } />;
    case 'case_study_media_columns':
      return <CaseStudyMediaColumns key={ type + '-' + index } data={ data } index={ index } />;
    case 'legal_paragraph':
      return <LegalParagraph key={ type + '-' + index } data={ data } index={ index } />;
    case 'case_study_video_columns':
      return <CaseStudyVideoColumns key={ type + '-' + index } data={ data } index={ index } />;
    case 'case_study_text_video':
      return <CaseStudyTextVideo key={ type + '-' + index } data={ data } index={ index } />;
    case 'contact_form':
      return <ContactForm key={ type + '-' + index } data={ data } index={ index } />;
    case 'newsletter_form':
      return <NewsletterForm key={ type + '-' + index } data={ data } index={ index } />;
    default:
      return <></>;
    }
  };
  return (
    <>
      {
        data?.node?.dataRaw?.body?.map( ( item, index ) => {
          return renderSwitch( item.slice_type, data?.node.dataRaw, index );
        } )
      }
    </>
  );
};

export const ComponentRenderService = ( props ) => {
  const staticData = useStaticQuery(graphql`
    query renderQuery {
      allPrismicSections {
        edges {
          node {
            _previewable
            prismicId
            dataRaw
          }
        }
      }
    }
  `);
  
  const data = useMergePrismicPreviewData(staticData);

  return (
    <RenderComponent
      data={ data.allPrismicSections.edges.find( ( { node } ) => node.prismicId === props.sections1.id ) } />
  );
};