import React, { useRef } from 'react';
import classnames from 'classnames';
// import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './CaseStudyMediaColumns.scss';
import { Media } from './Media';

export const CaseStudyMediaColumns = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  // TODO: Fix IntersectionObserver ref undefined.
  // const onScreen = useIntersectionObserver( wrapperRef );

  if (!data) return;
  const leftMedia = data.leftmedia && {
    ...data.leftmedia,
    alt: data.leftmediaalt || "case study left image",
    classnames: "left media" ,
    posterurl: data.leftposter?.url
  } || {};
  const rightMedia = data.rightmedia && {
    ...data.rightmedia,
    alt: data.rightmediaalt || "case study right media",
    classnames: "right media" ,
    posterurl: data.rightposter?.url
  } || {};
  return (
    <div className={ classnames( "case-study-media-columns-component visible", data?.layout) } ref={ wrapperRef }>
      <Media { ...leftMedia } />
      <Media { ...rightMedia } />
    </div>
  );
};