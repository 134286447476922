import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './TwoColumnsContent.scss';

export const TwoColumnsContent = ( props ) => {
  const data = props.data.body[ props.index ];
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "two-columns-content-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      { data?.primary?.subtitle && (
        <p className="tcc-subtitle">{ data?.primary?.subtitle }</p>
      ) }
      <div className="tcc-item-wrapper">
        {
          data?.items.map( ( item, index ) => {
            return (
              <div className="tcc-item" key={ index }>
                <p className="item-title">{ item.title }</p>
                <p className="item-description">{ item.description }</p>
              </div>
            );
          } )
        }
      </div>
    </div >
  );
};