import React, { useRef } from 'react';
import { CustomLink } from '../utils/CustomLink';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './HighlightTextBackground.scss';

export const HighlightTextBackground = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div
      className={ classnames( "highlight-text-background-component", data?.imageorvideo?.url ? '' : 'no-bg', onScreen?.isIntersecting ? 'visible' : '', data?.small_margin_bottom ? 'small-mb' : '' ) }
      ref={ wrapperRef }
    >
      <div className="htb-copy">
        { data?.subtitle && (
          <p>{ data?.subtitle }</p>
        ) }
        <h2 className={classnames( data?.big_text ? "big" : "", data?.small_text ? "small" : "" )}>{ data?.title }</h2>
        { data?.buttoncta && data?.buttontext && (
          <CustomLink element={ data?.buttoncta } content={ data?.buttontext }></CustomLink>
        ) }
      </div>
      { data?.imageorvideo?.kind === "image" && data?.imageorvideo?.url && (
        <div className="background">
          <img alt={ data?.imageorvideoalt || "background image" } src={ data?.imageorvideo?.url } />
        </div>
      ) }
      { data?.imageorvideo?.kind === "document" && data?.imageorvideo?.url && (
        <div className="background" dangerouslySetInnerHTML={ {
          __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                src="${ data?.imageorvideo?.url }"
              />
            ` } }></div>
      ) }
    </div>
  );
};