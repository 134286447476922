import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './GridImages.scss';

export const GridImages = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "grid-images-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <img alt={ data?.primaryimagealt || "main image" } src={ data?.primary_image.url } className="image-main" />
      <img alt={ data?.secondaryimageaalt || "secondary image top" } src={ data?.secondary_image_a.url } className="image-a" />
      <img alt={ data?.secondaryimagebalt || "secondary image bottom" } src={ data?.secondary_image_b.url } className="image-b" />
    </div>
  );
};