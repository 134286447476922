import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './Logos.scss';

export const Logos = ( props ) => {
  const data = props.data.body[ props.index ].items;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "logos-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="logo-wrapper">
        {
          data?.map( ( item, index ) => {
            return item.ctaurl && (
              <React.Fragment key={ index }>
                <img alt={ item.logoalt || "logo" } src={ item.logo.url } key={ index } style={ { "--widthDesktop": item.logo.width + 'px', "--widthMobile": item.logo.width * 0.64 + 'px' } } />
              </React.Fragment>
            );
          } )
        }
      </div>
    </div>
  );
};