// CustomLink.js file

import React from 'react';
import { Link } from 'gatsby';

export const CustomLink = ( { element, content } ) => {
  if ( element.link_type === "Document" ) {
    return (
      <Link to={ element.slug !== 'homepage' ? "/" + element.slug : "/" }>
        { content }
      </Link>
    );
  }

  if ( element.link_type === "Web" ) {
    return (
      <a href={ element.url } target="_blank" rel="noreferrer">
        { content }
      </a>
    );
  }

  return null;
};