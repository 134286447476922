import React, { useRef } from 'react';
import classnames from 'classnames';
// import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { RichText } from 'prismic-reactjs';
import './CaseStudyTwoColumnsMedia.scss';
import { Media } from './Media';

export const CaseStudyTwoColumnsMedia = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  // TODO: Fix IntersectionObserver ref undefined.
  // const onScreen = useIntersectionObserver( wrapperRef );

  if (!data) return;
  const media = data.medialink && {
    ...data.medialink,
    alt: data.mediaalt || "case study two columns media",
    classnames: "",
    posterurl: data.poster?.url
  } || {};

  return (
    <div className={ classnames( "case-study-two-columns-media-component visible", data?.fliplayout ? "flip" : "") } ref={ wrapperRef }>
      <div className="cstc-media">
        <Media {...media}/>
      </div>
      <div className="cstc-content">
        <div className="cstc-title">
          <h2>{ data?.title }</h2>
        </div>
        { data?.text && (
          <div className="cstc-description">{ RichText.render( data?.text ) }</div>
        ) }
      </div>
    </div>
  );
};