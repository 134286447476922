import React, { useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './ImageTextGrid.scss';

export const ImageTextGrid = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );


  return (
    <div className={ classnames( "image-text-grid-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="wrapper-items">
        <div className="left-item">
          <img alt={ data?.primaryimagealt || "primary image" } src={ data?.primary_image.url } />
        </div>
        <div className="center-item">
          { data?.primary_title && data?.primary_description && (
            <>
              { RichText.render( data?.primary_title ) }
              { RichText.render( data?.primary_description ) }
            </>
          ) }
        </div>
        <div className="right-item">
          <img alt={ data?.secondaryimagealt || "secondary image" } src={ data?.secondary_image.url } />
          { data?.secondary_title && data?.secondary_description && (
            <>
              { RichText.render( data?.secondary_title ) }
              { RichText.render( data?.secondary_description ) }
            </>
          ) }
        </div>
      </div>
    </div>
  );
};