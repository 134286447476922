import React, { useRef } from 'react';
import { CustomLink } from '../utils/CustomLink';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './ContactCards.scss';

export const ContactCards = ( props ) => {
  const data = props.data.body[ props.index ];
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );


  return (
    <div className={ classnames( "contact-cards-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="contact-cards-wrapper">
        <div className="contact-cards-left">
          <h2>
            { data?.primary?.titleleft }
          </h2>
          <div className="links-wrapper">
            {
              data?.items.map( ( item, index ) => {
                return item.linkcta && (
                  <CustomLink element={ item?.linkcta } content={ item?.linktitle } key={ index }></CustomLink>
                );
              } )
            }
          </div>
        </div>
        <div className="contact-cards-right">
          <h2>
            { data?.primary?.titleright }
          </h2>
          <h2>
            { data?.primary?.address }
          </h2>
        </div>
      </div>
    </div>
  );
};