import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { RichText } from 'prismic-reactjs';
import './CaseStudyTwoColumns.scss';

export const CaseStudyTwoColumns = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "case-study-two-columns-component", data?.fliplayout ? "flip" : "", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="cstc-image">
        <img src={ data?.image?.url } alt={ data?.imagealt || "case study two columns image" } />
      </div>
      <div className="cstc-content">
        <div className="cstc-title">
          <h2>{ data?.title }</h2>
        </div>
        { data?.text && (
          <div className="cstc-description">{ RichText.render( data?.text ) }</div>
        ) }
      </div>
    </div>
  );
};