import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './FullWidthImage.scss';

export const FullWidthImage = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "full-width-image-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="image-wrapper">
        <img
          alt={ data?.imagealt || "full width image" }
          width={data?.image?.dimensions.width}
          height={data?.image?.dimensions.height}
          src={ data?.image?.url } className="image-full" />
      </div>
    </div>
  );
};