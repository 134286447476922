import React, { useState } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import './NavigationHeader.scss';
import { Contact } from './Contact';

const Header = ( props ) => {
  const homepageClass = props.isHomepage ? 'homepage-header' : '';
  const [ menuOpen, setMenuOpen ] = useState( false );

  function toggleMenu () {
    setMenuOpen( !menuOpen );
  }

  const handleKeyDown = ( event ) => {
    if ( event.key === 'Enter' )
      toggleMenu();

  };

  return (
    <>
      <header className={ `site-header ${ homepageClass }` }>
        <div className='logo-box'>
          <Link to="/" className='logo'>
            { <img alt={ props.logoData[ 0 ].node.data.logo.alt || "header logo" } src={ props.logoData[ 0 ].node.data.logo.url } className='logo-default' /> }
          </Link>
          <div className={ menuOpen ? 'menu-btn menu-open' : 'menu-btn' } onClick={ toggleMenu } tabIndex="0" role="button" aria-label="Toggle menu" aria-pressed="mixed"
            onKeyDown={ handleKeyDown }>
            <div className='btn-burger'></div>
          </div>
        </div>
      </header>
      <Contact menuOpen={ menuOpen } pageData={ props.allPagesData } navigationData={ props.navigationData } />
    </>
  );
};

export const NavigationHeader = ( props ) => {
  return (
    <StaticQuery
      query={ graphql`
        query navigationPages {
          allPrismicPages {
            edges {
              node {
                prismicId
                data {
                  name
                  slug
                  fullname
                }
              }
            }
          }
          allPrismicNavigation {
            edges {
              node {
                data {
                  buttontext
                  buttoncta
                  email
                  links {
                    link {
                      id
                    }
                  }
                }
              }
            }
          }
          allPrismicNavigationHeader {
            edges {
              node {
                data {
                  logo {
                    alt
                    url
                  }
                }
              }
            }
          }
        }

      `}
      render={ data => <Header allPagesData={ data.allPrismicPages.edges } navigationData={ data.allPrismicNavigation.edges } logoData={ data.allPrismicNavigationHeader.edges } { ...props } /> }
    />
  );
};