import * as React from 'react';

import { NavigationHeader } from './NavigationHeader';
import { Footer } from './Footer';
import "../styles/base.scss";
import "../styles/common.scss";

export const Layout = ( { isHomepage, children, navigation } ) => (
  <>
    <NavigationHeader isHomepage={ isHomepage } navigation={ navigation } />
    { children }
    <Footer />
  </>
);
