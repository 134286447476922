import React, { useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { Link } from 'gatsby';
import './Cards.scss';


export const Cards = ( props ) => {
  const data = props.data.body[ props.index ].items;
  const title = props.data.body[ props.index ].primary?.title;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "cards-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      { title && (
        <div className="card-main-title">
          <h2>{ title[ 0 ]?.text }</h2>
        </div>
      ) }
      <div className="card-wrapper">
        {
          data?.map( ( item, index ) => {
            const isLIink = item.ctaurl.slug ? true : false;
            const DynamicTag = isLIink ? Link : 'div';

            return (
              <div className={`card-item ${isLIink ? 'is-link' : ''}`} key={ index }>
                <DynamicTag className='main-linkTo' to={ "/" + item.ctaurl.slug }>
                  <img alt={ item.cardimagealt || "card image" } src={ item.cardimage.url } />
                  <div className="card-text">
                    <p className="card-title">{ item.cardtitle }</p>
                    <p className="card-subtitle">{ item.cardsubtitle }</p>
                    <div className="item-description">
                      { item.carddescription && (
                        <>
                          { RichText.render( item.carddescription ) }
                        </>
                      ) }
                      { item.ctaurl && item.ctatext && (
                        <p className="card-url">{ item.ctatext }</p>
                      ) }
                    </div>
                  </div>
                </DynamicTag>
              </div>
            );
          } )
        }
      </div>
    </div>
  );
};