import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './CaseStudyImageColumns.scss';

export const CaseStudyImageColumns = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );
  const compressString = '?auto=compress,format';

  const checkForGif = imgUrl => {
    return imgUrl.search(".gif") != -1 ? imgUrl.replace(compressString, '') : imgUrl;
  }

  return (
    <div className={ classnames( "case-study-image-columns-component", data?.layout, onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <img alt={ data?.leftimagealt || "case study left image" } src={ checkForGif(data?.leftimage?.url) } className="left image" />
      <img alt={ data?.rightimagealt || "case study right image" } src={ checkForGif(data?.rightimage?.url) } className="right image" />
    </div>
  );
};
