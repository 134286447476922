import React, { useState, useRef } from 'react';
import Slider from "react-slick";
import { RichText } from 'prismic-reactjs';
import './Quotes.scss';
import CarouselArrow from '../images/quotes_arrow.svg';
import CarouselArrowHover from '../images/quotes_arrow_hover.svg';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import 'slick-carousel/slick/slick.css';

export const Quotes = (props) => {
  const data = props.data.body[props.index].items;
  const [sliderIndex, setSliderIndex] = useState(0);
  const slider = useRef(null);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  const sliderArrows =
    <div className="quotes-arrows">
      <span>
        <img alt="prev arrow" className={sliderIndex === 0 ? 'prev-arrow disabled' : 'prev-arrow'} src={CarouselArrow} onClick={() => slider?.current?.slickPrev()} />
        <img alt="prev arrow" className={sliderIndex === 0 ? 'prev-arrow hover-img disabled' : 'prev-arrow hover-img'} src={CarouselArrowHover} onClick={() => slider?.current?.slickPrev()} />
      </span>
      <span>
        <img alt="next arrow" className={sliderIndex === 3 ? 'next-arrow disabled' : 'next-arrow'} src={CarouselArrow} onClick={() => slider?.current?.slickNext()} />
        <img alt="next arrow" className={sliderIndex === 3 ? 'next-arrow hover-img disabled' : 'next-arrow hover-img'} src={CarouselArrowHover} onClick={() => slider?.current?.slickNext()} />
      </span>
    </div>

  let slickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    swipe: true,
    arrows: false,
    afterChange: (current) => {
      setSliderIndex(current);
    }
  };

  return (
    <div className={classnames("quotes-component", onScreen?.isIntersecting ? 'visible' : '')} ref={wrapperRef}>
      <div className="quotes-wrapper">
        <Slider ref={slider} {...slickSettings}>
          {
            data?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="quotes-item">
                    <div className="quotes-text">
                      {RichText.render(item?.title)}
                    </div>
                    <div className="quotes-by">
                      - {RichText.render(item?.quoteby)}
                    </div>
                    {item.logo.url &&
                      <img alt={item.logoalt || "slider image"} src={item.logo.url} />
                    }
                  </div>
                </React.Fragment>
              );
            })
          }
        </Slider>
        {data.length > 1 &&
          sliderArrows
        }
      </div>
    </div>
  );
};
