import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './CaseStudy.scss';

export const CaseStudy = ( props ) => {
  const data = props.data.body[ props.index ];
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "case-study-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="description-wrapper">
        <p>{ data?.primary?.subtitle }</p>
        <h2>{ data?.primary?.title }</h2>
        <div className="wrapper-tags">
          {
            data?.items.map( ( item, index ) => {
              return (
                <div className="tags-item" key={ index }>
                  { item.tags }
                </div>
              );
            } )
          }
        </div>
      </div>
      <img alt={ data?.primary?.desktopimagealt || "case study desktop image" } src={ data?.primary?.desktopimage.url } className='desktop-image' />
      <img alt={ data?.primary?.mobileimagealt || "case study mobile image" } src={ data?.primary?.mobileimage.url } className='mobile-image' />
    </div>
  );
};