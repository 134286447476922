import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './CaseStudyVideoColumns.scss';
import { Media } from './Media';

export const CaseStudyVideoColumns = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  const leftVideo = data.leftvideo && {
    ...data.leftvideo,
    alt: data.leftvideoalt || "left video" ,
    classnames: "left video" ,
    posterurl: data.leftposter?.url
  } || {};
  const rightVideo = data.rightvideo && {
    ...data.rightvideo,
    alt: data.rightvideoalt || "right video" ,
    classnames: "right video" ,
    posterurl: data.leftposter?.url
  } || {};

  return (
    <div className={ classnames( "case-study-video-columns-component", data?.layout, onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <Media {...leftVideo} />
      <Media {...rightVideo} />
    </div>
  );
};
