import * as React from "react";
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ComponentRenderService } from '../services/ComponentRenderService';
import { useEffect, useCallback } from "react";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import './pages.scss';

// markup
const IndexPage = ( { data } ) => {
  if ( !data ) return null;
  const doc = data.prismicPages.data;
  const resetWindowScrollPosition = useCallback( () => window.scrollTo( 0, 0 ), [] );

  useEffect( () => {
    window.onbeforeunload = function () {
      resetWindowScrollPosition();
    };
  }, [ resetWindowScrollPosition ] );

  useEffect(() => {
    if(window.location.pathname === '/privacy/'){
      const page = document.querySelector('.page');
      page.setAttribute('id', 'privacy-page');
    }
  }, []);

  useEffect(() => {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) 
      document.querySelector('.page').style.animation = "null";
    
  });

  return (
    <div className="page">
      <Layout isHomepage>
        <Seo title={ doc.pagetitle } description={ doc.metadescription } metatitle={ doc.metatitle } metaimage={ doc.metaimage } metaimagealt={ doc.metaimagealt }/>
        { doc.sections.map( ( section, index ) => {
          return (
            <div key={ index }>
              { ComponentRenderService( section ) }
            </div>
          );
        } ) }
      </Layout>
    </div>
  );
};

export const query = graphql`
  query PageQuery($id: String) {
    prismicPages(id: {eq: $id}, data: {}) {
      _previewable
      data {
        name
        pagetitle
        metatitle
        metadescription
        metaimage
        metaimagealt
        sections {
          sections1 {
            id
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(IndexPage);
