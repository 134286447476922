import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const Seo = ( { description, title, metatitle, metaimage, metaimagealt } ) => {
  const queryData = useStaticQuery( graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const pagetitle = title
    ? `${ title } | Left Field Labs`
    : 'Left Field Labs';
  const metaDescription =
    description || queryData.site?.siteMetadata?.description;

  const isInBrowser = typeof window !== 'undefined';
  const url = isInBrowser && window.location?.href || "http://www.leftfieldlabs.com/";
  return (
    <Helmet>
      <html lang="en" />
      <title>{ pagetitle }</title>
      <meta name="description" content={ metaDescription } />
      { metatitle && <meta property="og:title" content={ metatitle } />}

      { metaDescription && <meta property="og:description" content={metaDescription} /> }

      { metaimage && <meta property="og:image" content={metaimage} />}
      { metaimagealt && <meta name="twitter:image:alt" content={metaimagealt} />}
      { url && <meta property="og:url" content={url} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Left Field Labs"></meta>      
    </Helmet>
  );
};
