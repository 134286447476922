import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { Link } from 'gatsby';
import './Contact.scss';

export const Contact = ( props ) => {
  const [ menuData, setMenuData ] = useState( [] );
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  useEffect( () => {
    const menu = [];
    props.navigationData[ 0 ].node.data.links.map( link => {
      props.pageData.map( page => {
        if ( link.link.id === page.node.prismicId )
          menu.push( page.node.data );
      } );
    } );
    setMenuData( menu );
  }, [ props ] );

  const onMenuItemClick = (evt, item) => {
    if (!evt.key || evt.key === 'Enter') 
      window.location.href = "/" + item.slug; 
  };

  return (
    <>
      <div className={ classnames( props.menuOpen ? 'contact-component contact-component-open' : 'contact-component', onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
        <div className="container">
          <div className="column column-left">
            <ul className="menu-items">

              {
                menuData.map( ( item, index ) => {
                  return (
                    <li key={ index }>
                      <span tabIndex={0} onKeyUp={ (e) => { onMenuItemClick(e, item); } } onClick={ (e) => { onMenuItemClick(e, item); } }>
                        <span className="page-title">{ item.name }</span>
                        <span className="page-description">{ item.fullname }</span>
                      </span>
                    </li>
                  );
                } )
              }

            </ul>
          </div>
          <div className="column column-right">
            <div className="contact-items">              
              <a href={ props.navigationData[ 0 ].node.data.buttoncta } className="additional-link" target="_blank" rel="noreferrer">{ props.navigationData[ 0 ].node.data.buttontext }</a>
              <a className="mail" href={ `mailto: ${ props.navigationData[ 0 ].node.data.email }` }>{ props.navigationData[ 0 ].node.data.email }</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};