import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './ContactForm.scss';
import CheckIcon from '../images/check-icon.svg';
import LoaderIcon from '../images/loading-icon.svg';

export const ContactForm = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const formRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );
  const [ success, setSuccess ] = useState( false );
  const [ submitting, setSubmitting ] = useState( false );

  const resetFormFields = () => {
    const myForm = formRef.current;
    myForm.reset();
    myForm[ 'consent' ].nextElementSibling.classList.remove( 'validated' );
    myForm.querySelector( '.error' ).classList.remove( 'visible' );
  };

  const handleSubmit = ( event ) => {
    event.preventDefault();

    const myForm = formRef.current;

    if ( submitting ) return;

    if ( success ) {
      resetFormFields();
      setSuccess( false );
      return;
    }
    
    // remove error message
    myForm.querySelector( '.error' ).classList.remove( 'visible' );

    // add campaign code to hidden input
    myForm[ 'campaign-code' ].setAttribute( 'value', data.campaign_code );
    
    // validate form
    if ( myForm.checkValidity() === false ) {
      myForm.reportValidity();

      if ( myForm[ 'consent' ].checkValidity() === false )
        myForm[ 'consent' ].nextElementSibling.classList.add( 'validated' );

      return;
    }

    const formData = new FormData( myForm );

    setSubmitting( true );

    fetch( "/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams( formData ).toString(),
    } )
      .then( () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push( {
          event: "formSubmission",
          formType: "Contact Form",
          campaign_code: data.campaign_code,
        } );
        setSubmitting( false );
        setSuccess( true );
        resetFormFields();
      } ) 
      .catch( ( error ) => {
        setSubmitting( false );
        myForm.querySelector( '.error' ).classList.add( 'visible' );
        console.log( error );
      } );
  };

  return (
    <div className={ classnames( "contact-form-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="form-container">
        <div className="heading">
          <span>Contact</span>
        </div>
        <form
          id="contact-form"
          name="contact-form"
          ref={ formRef }
          /* eslint-disable */
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <input
            type="hidden"
            name="campaign-code"
            value=""
          />
          <div className="left">
            <label>
                First Name:
              <input type="text" name="first-name" placeholder="First Name*" required />
            </label>
            <label>
                Last Name:
              <input type="text" name="last-name" placeholder="Last Name*" required />
            </label>
            <label>
                Email Address:
              <input type="email" name="email" placeholder="Email Address*" required />
            </label>
            <label>
                Company Name:
              <input type="text" name="company" placeholder="Company Name" />
            </label>
            <label>
                Job Title:
              <input type="text" name="job" placeholder="Job Title" />
            </label>
          </div>
          <div className="right">
            <label>
              <span>How can we assist you?</span>
              <textarea name="message" />
            </label>
            <div className="radio-container">
              <input type="checkbox" id="contact-consent" name="consent" className="radio" required />
              <label htmlFor="contact-consent">I consent to receive communications from Left Field Labs regarding products and services.</label>
            </div>
            <div className="pp">
              <a href="https://www.leftfieldlabs.com/privacy" rel="noreferrer" target="_blank">
                Privacy Policy
              </a>
            </div>
            <button className={`submit ${submitting ? 'submitting' : ''} ${success ? 'success' : ''}`} onClick={handleSubmit}>{
              submitting ? (
                <div className="loader">
                  <img alt="Loader Icon" aria-hidden src={LoaderIcon} />
                </div>
              ) : success ? (
                <img alt="Check Icon" aria-hidden src={CheckIcon} />
              ) : 'Submit'
            }</button>
            <div className="error">
              An error occurred. Please try again.
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};