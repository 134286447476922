import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { RichText } from 'prismic-reactjs';
import { CustomLink } from '../utils/CustomLink';
import './StaticTwoColumn.scss';

export const StaticTwoColumn = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "static-two-column-component", data?.fliplayout ? "flip" : "", onScreen?.isIntersecting ? 'visible' : '', data?.alternate_layout ? "alt" : "" ) } ref={ wrapperRef }>
      <div className="content-wrapper">
        <h2>{ data?.title }</h2>
        { data?.description && (
          <div className="description">{ RichText.render( data?.description ) }</div>
        ) }
        { data?.subtitle && (
          <p className="subtitle">{ data?.subtitle }</p>
        ) }
        { data?.buttoncta && data?.buttontext && (
          <CustomLink element={ data?.buttoncta } content={ data?.buttontext }></CustomLink>
        ) }
      </div>
      <div className="imageorvideo-wrapper">
        { data?.imageorvideo?.kind === "image" && data?.imageorvideo?.url && (
          <div className="imageorvideo">
            <img src={ data?.imageorvideo?.url } alt={ data?.imageorvideoalt || "static two column image" } />
          </div>
        ) }
        { data?.imageorvideo?.kind === "document" && data?.imageorvideo?.url && (
          <div className="imageorvideo" dangerouslySetInnerHTML={ {
            __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                src="${ data?.imageorvideo?.url }"
              />
            ` } }></div>
        ) }
        { data?.image_caption && (
          <p className="caption">{ data?.image_caption }</p>
        ) }
      </div>
    </div>
  );
};