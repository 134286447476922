import React, { useRef, useLayoutEffect }  from "react";
import './Media.scss';

export const Media = (mediaProps) => {
  const { kind, url, alt, classnames, posterurl } = mediaProps;
  if (!url) return;

  const videoParentRef = useRef();
  
  useLayoutEffect(() => {
    // check if we have the ref to the container <div />
    if (videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[ 0 ];

      // if the reference to video player has been obtained
      if (player) {
        player.muted = true;
        player.setAttribute("muted", "");
        
        // if we are not in mobile devices, play de video
        if(window.innerWidth > 600){
          player.autoplay = true;
          setTimeout(() => {
            player.play();
          }, 0);
        }
      }
    }
  }, []);
  const poster = posterurl && `poster=${posterurl}` || "";
  switch (kind) {
  case "image":
    return <img alt={alt} src={url} className={classnames} />;
  case "document":
    return (
      <div
        className={classnames}
        ref={videoParentRef}
        dangerouslySetInnerHTML={{
          __html: `
                <video
                  ${poster}
                  loop
                  muted
                  playsinline
                  src="${url}"
                >
                </video>`,
        }}
      />
    );
  }
};
