import React, { useRef } from 'react';
import { CustomLink } from '../utils/CustomLink';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './HighlightTextTwoColumns.scss';

export const HighlightTextTwoColumns = (props) => {
  const data = props.data.body[props.index].primary;
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);

  return (
    <div className={classnames("highlight-text-two-columns-component", onScreen?.isIntersecting ? 'visible' : '')} ref={wrapperRef}>
      <div className="httc-wrapper">
        <div className="httc-copy">
          {data?.subtitle && (
            <p>{data?.subtitle}</p>
          )}
          <h2>{data?.title}</h2>
        </div>
        <div className="httc-cta">
          {data?.buttoncta && data?.buttontext && (
            <CustomLink element={data?.buttoncta} content={data?.buttontext}></CustomLink>
          )}
        </div>
      </div>
    </div>
  );
};
