import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { RichText } from 'prismic-reactjs';
import './TextInColumns.scss';

export const TextInColumns = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "text-in-columns-component", data?.description_size.toLowerCase(), onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="tic-left">
        <h2>{ data?.title }</h2>
      </div>
      <div className="tic-right">
        { data?.description && (
          <div>{ RichText.render( data?.description ) }</div>
        ) }
      </div>
    </div>
  );
};