import React, { useRef } from 'react';
import classnames from 'classnames';
// import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './GridMedia.scss';
import { Media } from './Media';

export const GridMedia = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  // TODO: Fix intersection observer 
  // const onScreen = useIntersectionObserver( wrapperRef );

  if (!data) return;
  const primaryMedia = data.primary_media && {
    ...data.primary_media,
    alt: data.primarymediaalt || "main media",
    classnames: "media-main" ,
    posterurl: data.primary_poster?.url
  } || {};
  const secondaryMediaA = data.secondary_media_a && {
    ...data.secondary_media_a,
    alt: data.secondarymediaaalt || "secondary media top",
    classnames: "media-a" ,
    posterurl: data.secondary_poster_a?.url
  } || {};
  const secondaryMediaB = data.secondary_media_b && {
    ...data.secondary_media_b,
    alt: data.secondarymediabalt || "secondary media bottom",
    classnames: "media-b" ,
    posterurl: data.secondary_poster_b?.url
  } || {};

  return (
    <div className={ classnames( "grid-media-component visible")} ref={ wrapperRef }>
      <Media { ...primaryMedia } />
      <Media { ...secondaryMediaA } />
      <Media { ...secondaryMediaB } />
    </div>
  );
};