import React, { useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import './RichText.scss';

export const RichTextComponent = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  return (
    <div className={ classnames( "rich-text-component", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      { data?.title && (
        <p className="title">{ data?.title }</p>
      ) }
      { data?.description && (
        <div className="wrapper-items">
          { RichText.render( data?.description ) }
        </div>
      ) }
    </div>
  );
};