import * as React from 'react';
import {
  PrismicPreviewProvider,
} from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/utils/LinkResolver';
import PageTemplate from './src/pages/{PrismicPages.url}';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: {
          page: PageTemplate,
        },
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);