import React, { useRef } from 'react';
import classnames from 'classnames';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { RichText } from 'prismic-reactjs';
import './CaseStudyTextVideo.scss';
import { Media } from './Media';

export const CaseStudyTextVideo = ( props ) => {
  const data = props.data.body[ props.index ].primary;
  const wrapperRef = useRef( null );
  const onScreen = useIntersectionObserver( wrapperRef );

  const video = data.video && {
    ...data.video,
    alt: data.videoalt || "case study video" ,
    classnames: "video" ,
    posterurl: data.poster?.url
  } || {};

  return (
    <div className={ classnames( "case-study-text-video-component", data?.fliplayout ? "flip" : "", onScreen?.isIntersecting ? 'visible' : '' ) } ref={ wrapperRef }>
      <div className="cstv-video">
        <Media {...video} />
      </div>
      <div className="cstv-content">
        <div className="cstv-title">
          <h2>{ data?.title }</h2>
        </div>
        { data?.text && (
          <div className="cstv-description">{ RichText.render( data?.text ) }</div>
        ) }
      </div>
    </div>
  );
};