// LinkResolver.js file

exports.linkResolver = ( doc ) => {
  if ( doc.type === "contact" ) return "/contact";

  if ( doc.type === "contact-success" ) return "/contact-success";

  if ( doc.type === 'pages' ) {
    if ( doc.data?.slug ) 
      return `/${ doc.data?.slug }`;
    else 
      return '/';
  }

  return '/';
};
